<template>
  <div>
    <b-form-group
      id="sigle-legam-group"
      label="Sigle LEGaM:"
      label-for="sigle-legam"
    >
      <b-form-input
        id="sigle-legam"
        v-model="model.sigleLegam"
        placeholder="Sigle LEGaM"
      ></b-form-input>
    </b-form-group>

    <!-- <b-form-group
      id="sigle-other-group"
      label="Sigle Other:"
      label-for="sigle-other"
    >
      <b-form-input
        id="sigle-other"
        v-model="model.sigleOther"
        placeholder="Sigle Other"
      ></b-form-input>
    </b-form-group> -->

    <b-row>
      <b-col>
        <b-form-group
          id="dates-of-life-group"
          label="Dates of life:"
          label-for="dates-of-life"
        >
          <b-form-input
            id="dates-of-life"
            v-model="model.datesOfLife"
            placeholder="Dates of life"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          id="dates-of-life-num-group"
          label="Dates of life (num):"
          label-for="dates-of-life-num"
        >
          <b-form-input
            id="dates-of-life-num"
            v-model="model.datesOfLifeNum"
            placeholder="Dates of life (num)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          id="dates-of-activity-group"
          label="Dates of activity:"
          label-for="dates-of-activity"
        >
          <b-form-input
            id="dates-of-activity"
            v-model="model.datesOfActivity"
            placeholder="Dates of activity"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          id="dates-of-activity-num-group"
          label="Dates of activity (num):"
          label-for="dates-of-activity-num"
        >
          <b-form-input
            id="dates-of-activity-num"
            v-model="model.datesOfActivityNum"
            placeholder="Dates of activity (num)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-form-group
      id="comment-general-group"
      label="Comment general:"
      label-for="comment-general"
    >
      <b-form-textarea
        id="comment-general"
        placeholder="Comment general"
        v-model="model.commentGeneral"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </b-form-group>

    <b-form-group
      id="reference-group"
      label="Reference"
      label-for="reference"
    >
      <b-form-textarea
        id="reference"
        placeholder="Reference"
        v-model="model.reference"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </b-form-group>
  </div>
</template>

<style scoped>
</style>

<script>
  import HelpStore from '@/store/helpstore.js'

  export default {
    name: 'AuthorhistNewEdit',
    props: ['authorhist'],
    data(){
      return {
        // TODO: fetch from API
        model: (this.authorhist ? this.authorhist : {
          sigleLegam: null,
          sigleOther: null,
          datesOfLife: null,
          datesOfLifeNum: null,
          datesOfActivity: null,
          datesOfActivityNum: null,
          commentGeneral: null
        })
      }
    },
    updated(){
      HelpStore.item = this.model
    },
    methods: {
      // selectCategory(category){
      //   this.model.categories.push(category)
      // }
    }
  }
</script>
