<template>
  <div>
    <b-container fluid="xxl" class="mt-2">
      <b-row class="mt-4">
        <b-col cols="4" class="p-0">
          <PageTitle v-bind:title="'Historical Authors'" />
        </b-col>
        <b-col cols="8" class="text-right" v-if="!config.editable">
          <BibLabelSelectPI class="mb-3" />
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <div v-if="$store.state.user.settings.isBiblabelFiltered && config.editable" class="mb-3 text-orange">
            <b-icon icon="exclamation-circle-fill" aria-hidden="true"></b-icon>
            Entries in this table are filtered by personal preference for bibliography set in user settings
            ({{ $store.getters.getBibLabelsByIds($store.state.user.settings.biblabels).map(biblabel => biblabel.label).join(', ') }})
          </div>
          <b-button v-if="config.editable" variant="primary" size="sm" :disabled="!$store.getters.checkRole('legam.bib.authorhist.add')" class="float-right mb-4" v-b-modal.modal-new-authorhist>
            <b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon>
            Add new historical author
          </b-button>
          <b-modal v-if="config.editable" id="modal-new-authorhist" title="New historical author" ok-title="Save" @ok="saveAuthorhist" size="lg">
            <AuthorhistNewEdit />
          </b-modal>
          <LegamDataTables v-if="config.editable" v-bind="$data" :additionalData="tableAdditionalData" />
          <LegamDataTablesClientSide
            v-else
            v-bind="$data"
            :additionalData="tableAdditionalData"
            @click="openDetails"
            :additionalDataFilter="tableAdditionalDataFilter"
            ref="PITable"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>
  * >>> .tdAction {
    width: 150px;
  }
</style>

<script>
  import { mapState } from 'vuex'
  import router from '@/router'
  import PageTitle from '@/components/PageTitle.vue'
  import LegamDataTables from '@/components/LegamDataTables.vue'
  import LegamDataTablesClientSide from '@/components/LegamDataTablesClientSide.vue'
  import BibLabelSelectPI from '@/components/Bibliography/BibLabelSelectPI.vue'
  import tdColAction from "@/components/tdColAction";
  import tdColSafeHTML from "@/components/tdColSafeHTML"
  import AuthorhistNewEdit from "@/components/Bibliography/AuthorhistNewEdit"
  import HelpStore from '@/store/helpstore.js'
  import config from '@/config.js'

  export default {
    name: 'HistoricalAuthors',
    data(){
      return {
        columns: [
          { title: 'Sigle', field: 'sigleLegam', sortable: true, searchable: true },
          { title: this.$t('DatesOfLife'), field: 'datesOfLife', sortable: true, searchable: true },
          { title: this.$t('DatesOfLifeNum'), field: 'datesOfLifeNum', sortable: true, searchable: true },
          { title: this.$t('DatesOfActivity'), field: 'datesOfActivity', sortable: true, searchable: true },
          { title: 'Comment', field: 'commentGeneral', sortable: true, tdComp: tdColSafeHTML, searchable: true },
          { title: 'Label', field: 'biblabels', sortable: true, searchable: true },
          { title: 'Action', field: 'id', tdComp: tdColAction, thClass: 'tdAction',
            transformData: function (row) {
              return [
                { text: null, icon: 'list-ul', id: row.id, to: {
                  name: 'HistoricalAuthorDetails',
                  params: {
                    slug: row.sigleLegamNormalized || "-",
                    id: row.id
                  }}
                }
              ]
            }
          }
        ],
        columnsPI: [
          { title: 'Sigle', field: 'sigleLegam', sortable: true, searchable: true },
          { title: 'Dates of Life', field: 'datesOfLife', sortable: true, searchable: true },
          { title: 'Dates of Life Num', field: 'datesOfLifeNum', sortable: true, searchable: true },
          { title: 'Dates of Activity', field: 'datesOfActivity', sortable: true, searchable: true },
          { title: 'Comment', field: 'commentGeneral', sortable: true, tdComp: tdColSafeHTML, searchable: true },
          { title: 'Label', field: 'biblabels', sortable: true, searchable: false },
        ],
        url: '/authorhists/datatables',
        key: 1,
        searchableByColumn: true,
        config: config,
      }
    },
    computed: {
      ...mapState({
        userSettings: state => state.user.settings,
        userSettingsPI: state => state.user.settingsPI,
      }),
      tableAdditionalData(){
        return {
          'biblabel': this.userSettings.biblabels,
          'biblabelSuppressed': this.userSettings.biblabelsSuppressed,
        }
      }
    },
    components: {
      PageTitle,
      LegamDataTables,
      LegamDataTablesClientSide,
      AuthorhistNewEdit,
      BibLabelSelectPI,
    },
    methods: {
      tableAdditionalDataFilter(data) {
        if (this.userSettingsPI.biblabels.length) {
          data = data.filter(row => this.userSettingsPI.biblabels.includes(row.biblabels))
        }
        return data
      },
      saveAuthorhist(){
        this.$store.dispatch('createAuthorhist', HelpStore.item).then((response) => {
          router.push(`/historicalAuthor/${response.data.sigleLegamNormalized}/${response.data.id}`)
        })
      },
      openDetails(data) {
        router.push(`/historicalAuthor/-/${data.id}`)
      },
    },
    watch: {
      "userSettings"(){
        this.key++
      },
      userSettingsPI: {
        deep: true,
        handler() {
          if (this.$refs.PITable.table) {
            this.$refs.PITable.sortPageFilter()
          }
        }
      },
    }
  }
</script>
